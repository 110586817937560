import { graphql } from "gatsby"; 
import { inject, observer } from "mobx-react";
import * as React from "react";

import { OrderStore, OrderItem, UIStore, ShopStore, VisitorStore } from "stores";

import Checkbox from "../../components/checkbox";
import Button from "../../components/button";
import ShopHeader from "../../components/shopHeader";
import Loader from "../../components/loader";
import { getOrderTotals } from "../../utils/totals";
import { observable } from "mobx";
import SelectList from "../../components/selectList";
import SEO from "../../components/seo";
import { PAGE_VIEW_EVENT, NAVIGATE_EVENT, PLACE_ORDER_EVENT } from "../../utils/constants";


import paypalImg from "../../static/paypal.png"
import multisafePayImg from "../../static/multisafepay.jpg"

@inject("orderStore", "shopStore", "uiStore", "visitorStore")
@observer
class OrderPage extends React.Component<any> {
  @observable agreed = false;
  constructor(props: any) {
    super(props);
  }
  componentWillMount() {
    const config = this.props.data.squidexConfig;
    if (config.paymentMethods[process.env.GATSBY_LANGUAGE].length === 1) {
      const orderStore: OrderStore = this.props.orderStore;
      orderStore.current.paymentMethod = config.paymentMethods[process.env.GATSBY_LANGUAGE][0].paymentMethod;
    }
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "shop-order"
    });
  }
  render() {
    return (
      <>
        <SEO subTitle={this.props.data.squidexTexts.pageShopOrder[process.env.GATSBY_LANGUAGE]}/>
        {this.maybeRenderLoader()}
        <ShopHeader location={this.props.location} />
        {this.renderCart()}
        {this.renderTotals()}
        {this.renderCustomerDetails()}
        {this.renderPaymentSection()}
      </>
    )
  }
  maybeRenderLoader = () => {
    const store: OrderStore = this.props.orderStore;
    if (store.fetching) {
      return (<Loader />);
    }
    return null;
  }
  renderCart = () => {
    const store: OrderStore = this.props.orderStore;
    const texts = this.props.data.squidexTexts;
    return (
      <section id="orderoverview">
        <div className="dt mt2 w-100">
          <div className="dt-row w-100">
            <div className="pv2 dtc b w3 bb b--black-10">{/*texts.txtImage[process.env.GATSBY_LANGUAGE]*/}</div>
            <div className="pv2 dtc b pl3 bb b--black-10">{texts.txtProduct[process.env.GATSBY_LANGUAGE]}</div>
            <div className="pv2 dtc b pl3 w3 bb b--black-10">{texts.txtAmount[process.env.GATSBY_LANGUAGE]}</div>
            <div className="pv2 dn dtc-ns w4 b tr pl3 bb b--black-10">{texts.txtPricePerPiece[process.env.GATSBY_LANGUAGE]}</div>
            <div className="pv2 dn dtc-ns w4 b tr pl3 bb b--black-10">{texts.txtSubtotal[process.env.GATSBY_LANGUAGE]}</div>
          </div>
        </div>
        {
          store.current.items.map(this.renderItem)
        }
      </section>
    )
  }
  renderItem = (i: OrderItem, index: number) => {
    const texts = this.props.data.squidexTexts;
    return (
      <div id={`item-${index}`} key={`item-${index}`}>
        <div className="dt mt2 w-100">
          <div className="dt-row w-100">
            <div className="dtc v-mid w3">
              <img className="br3 ba b--black-10 h3 w3" src={i.thumb} />
            </div>
            <div className="dtc pl3">
              <p className="b">{i.material.name}</p>
              <p>{texts.txtFormat[process.env.GATSBY_LANGUAGE]}: {i.absWidth}x{i.absHeight}</p>
              {
                i.options.map((o, index) => (
                  <p key={`item-${index}-opt-${o.id}`}>- {o.name}</p>
                ))
              }
            </div>
            <div className="dtc v-mid w3 pl3">
              <div className="dib w-100 tc relative">
                {i.amount}
              </div>
            </div>
            <div className="dn dtc-ns v-mid w4 tr pl3">
              &euro; {i.price.toFixed(2)}
            </div>
            <div className="dn dtc-ns v-mid tr w4 pl3">
              &euro; {(i.price * i.amount).toFixed(2)}
            </div>
          </div>
        </div >
      </div>
    )
  }
  renderTotals = () => {
    const { subTotal, discount, deliverCost, giftAmount, total } = getOrderTotals(
      this.props.shopStore,
      this.props.orderStore
    );
    const texts = this.props.data.squidexTexts;
    return (
      <section id="totals" className="cf">
        <div className="fr-ns w-100 w-third-ns">
          <div className="dib w-two-thirds tl tr-ns">
            <p className="b mb2">{texts.txtSubtotal[process.env.GATSBY_LANGUAGE]}</p>
            {discount > 0 ? <p className="b mb2">{texts.txtDiscount[process.env.GATSBY_LANGUAGE]}</p> : undefined}
            {giftAmount > 0 ? <p className="b mb2">{texts.txtGiftcard[process.env.GATSBY_LANGUAGE]}</p> : undefined}
            <p className="b mb2">{texts.txtDeliverCosts[process.env.GATSBY_LANGUAGE]}</p>
            <div className="mt1 mb1 w-100 bb b--black-20"></div>
            <p className="b">{texts.txtTotal[process.env.GATSBY_LANGUAGE]}</p>
          </div>
          <div className="dib w-third tr">
            <p className="mb2">&euro; {subTotal.toFixed(2)}</p>
            {discount > 0 ? <p className="mb2">&euro; -{discount.toFixed(2)}</p> : undefined}
            {giftAmount > 0 ? <p className="mb2">&euro; -{giftAmount.toFixed(2)}</p> : undefined}
            <p className="mb2">&euro; {deliverCost.toFixed(2)}</p>
            <div className="mt1 mb1 w-100 bb b--black-20"></div>
            <p>&euro; {total.toFixed(2)}</p>
          </div>
        </div>
      </section>
    )
  }
  renderCustomerDetails = () => {
    const store: OrderStore = this.props.orderStore;
    const customer = store.current.customer;
    const texts = this.props.data.squidexTexts;
    return (
      <section id="customerDetails" className="cf mt2">
        <div className="fl w-100 w-40-ns mb3">
          <div className="mw6 mt2-ns center mr0-l ml0-l b--black-10 ba">
            <h3 className="f4 bg-theme white br--top-ns mv0 pv2 ph3 tc">{texts.txtCustomerDeliveryAddress[process.env.GATSBY_LANGUAGE]}</h3>
            <div className="pa3 bt b--black-10 mw6">
              <p className="mt0">{customer.firstName} {customer.lastName}</p>
              <p>{customer.street}</p>
              <p>{customer.postalCode} {customer.place}</p>
              <p className="mb0">{customer.country}</p>
            </div>
          </div>
        </div>
        <div className="fr w-100 w-40-ns mw6 mt2-ns center mr0-l ml0-l b--black-10 ba mb3">
          <h3 className="f4 bg-theme white br--top-ns mv0 pv2 ph3 tc">{texts.txtCustomerInvoiceAddress[process.env.GATSBY_LANGUAGE]}</h3>
          <div className="pa3 bt b--black-10 mw6">
            <p className="mt0">{customer.invoiceFirstName} {customer.invoiceLastName}</p>
            <p>{customer.invoiceStreet}</p>
            <p>{customer.invoicePostalCode} {customer.invoicePlace}</p>
            <p className="mb0">{customer.invoiceCountry}</p>
          </div>
        </div>
      </section>
    )
  }
  renderPaymentSection = () => {
    const texts = this.props.data.squidexTexts;
    const config = this.props.data.squidexConfig;
    let paymentMethods;
    if (config.paymentMethods[process.env.GATSBY_LANGUAGE].length > 1) {
      paymentMethods = config.paymentMethods[process.env.GATSBY_LANGUAGE].map((pm) => {
        return { label: pm.label, value: pm.paymentMethod}
      })
    }
    return (
      <section id="finalize" className="cf mt4-ns mt2">
        <div className="fn fr-ns w-40-ns">
          <Checkbox label={<div className="ml2" dangerouslySetInnerHTML={{__html: texts.txtAgreeToPurchase[process.env.GATSBY_LANGUAGE].html}}/>} onChange={this.handleAgreeChange} />
          { paymentMethods ? <div>
            <SelectList items={paymentMethods} label={texts.txtPaymentMethod[process.env.GATSBY_LANGUAGE]} />
          </div> : undefined}
          <div className="flex flex-column items-center ma3">
            <Button size={3} color="theme" label={texts.btnFinalizeOrder[process.env.GATSBY_LANGUAGE]} onClick={this.handlePlaceOrder} />
          </div>
          <div className="fn mb2">
            <img src={multisafePayImg} alt="multisafepay" height="32px" />
            <img src={paypalImg} alt="paypal" height="32px" />
          </div>
        </div>
        <div className="fn fl-ns w-50-ns">
          <article className="dib center ba b--gray bg-light-gray">
            <div className="dib pa3 relative center">
              <div className="dib center">
                <div className="dib tc tl-ns">
                  <p className="black-70 measure lh-copy mv0">
                    {texts.txtMultisafepayRedirect[process.env.GATSBY_LANGUAGE]}
                  </p>
                </div>
              </div>
            </div>
          </article></div>
        <div className="cf mt2">
        </div>
      </section>
    )
  }
  handleAgreeChange = (v: boolean) => {
    this.agreed = v;
  }
  handlePlaceOrder = () => {
    const store: OrderStore = this.props.orderStore;
    const uiStore: UIStore = this.props.uiStore;
    const visitorStore: VisitorStore = this.props.visitorStore;
    const texts = this.props.data.squidexTexts;
    if (!this.agreed) {
      uiStore.showModal(texts.txtValidationErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtNoAgreeToPurchaseError[process.env.GATSBY_LANGUAGE]);
      return;
    }
    const returnUrl = this.props.data.squidexOrderCompletedPage.slug.iv.toLowerCase();
    const cancelUrl = this.props.location.pathname;
    visitorStore.logEvent(PLACE_ORDER_EVENT, store.current)
    store.placeOrder(returnUrl, cancelUrl).then(
      (response) => {
        if (!response || !response.result) {
          uiStore.showModal(texts.txtGenericErrorHeader[process.env.GATSBY_LANGUAGE], texts.txtGenericErrorBody[process.env.GATSBY_LANGUAGE]);
          return;
        }
        if (!response.result.paymentUrl) {
          visitorStore.logEvent(NAVIGATE_EVENT, {
            location: this.props.location,
            href: this.props.data.squidexOrderCompletedPage.slug.iv.toLowerCase()
          });        
          this.props.navigate(this.props.data.squidexOrderCompletedPage.slug.iv.toLowerCase());
        } else {
          
          visitorStore.logEvent(NAVIGATE_EVENT, {
            location: this.props.location,
            href: response.result.paymentUrl
          })   
          window.location = response.result.paymentUrl as any;
        }
      }
    );
  }
}

export default OrderPage;
export const pageQuery = graphql`
{
  squidexOrderCompletedPage(identity: {eq: "orderCompleted"}) {
    slug {
      iv
    }
  }
  squidexConfig(identity: {eq: "config"}) {
    paymentMethods {
      de {
        label
        paymentMethod
      }
      nl {
        label
        paymentMethod
      }
      en {
        label
        paymentMethod
      }
    }
  }
  squidexTexts(identity: {eq: "squidextexts"}) {
    pageShopOrder {
      de
      nl
      en
    }
    txtImage {
      de
      nl
      en
    }
    txtProduct {
      de
      nl
      en
    }
    txtPaymentMethod {
      de
      nl
      en
    }
    txtFormat {
      de
      nl
      en
    }
    txtAmount {
      de
      nl
      en
    }
    txtPricePerPiece {
      de
      nl
      en
    }
    txtSubtotal {
      de
      nl
      en
    }
    txtTotal {
      de
      nl
      en
    }
    txtDiscount {
      de
      nl
      en
    }
    txtGiftcard {
      de
      nl
      en
    }
    txtDeliverCosts {
      de
      nl
      en
    }
    txtCustomerDeliveryAddress{
      de
      nl
      en
    }
    txtCustomerInvoiceAddress {
      de
      nl
      en
    }
    txtMultisafepayRedirect {
      de
      nl
      en
    }
    txtAgreeToPurchase {
      de {
        html
      }
      nl {
        html
      }
      en {
        html
      }
    }
    btnFinalizeOrder {
      de
      nl
      en
    }
    txtGenericErrorHeader {
      de
      nl
      en
    }
    txtGenericErrorBody {
      de
      nl
      en
    }
    txtValidationErrorHeader {
      de
      nl
      en
    }
    txtNoAgreeToPurchaseError {
      de
      nl
      en
    }
  }
}
`

